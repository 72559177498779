.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    margin-top: 60px;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.505);
}

.modal_container {
    margin: 10px auto;
}

.rsis-image {
    width: 100% !important;
    height: 100% !important;
    background-size: cover !important;
}

@media screen and (min-width: 568px) {}