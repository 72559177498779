.contact_header {
    display: flex;
    margin: 5px 10px;
}

.contact_title {
    font-size: var(--h2-font-size);
    color: var(--title-color);
    text-transform: capitalize;
    margin: auto;
    word-spacing: 5px;
    text-decoration: underline;
}

.contact_section {
    width: 80vw;
    margin: auto;
}

.contact_container {
    background-color: var(--first-color-second);
    padding: 2.5rem;
}

.locmailname {
    width: 50%;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

.contact_phone {
    color: white;
    border-bottom: 1px solid white;
    margin: 20px;
    padding: 2px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: fit-content;
}

.contact_container_phone {
    margin-bottom: 10px;
}

.contact_container_location {
    margin-bottom: 10px;
}

.contact_container_box {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.locmailname:hover {
    text-decoration: underline;
}

@media screen and (max-width: 750px) {
    .contact_container {
        background-color: var(--first-color-second);
    }
    .contact_phone {
        color: white;
        margin: 20px;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .contact_container_phone {
        width: 50px;
    }
    .contact_container_email {
        width: 50px;
    }
    .contact_container_location {
        width: 50px;
    }
    .contact_container_box {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .project_btn {
        width: 20px;
    }
    .locmailname {
        width: 80%;
        color: white;
        font-size: 20px;
        cursor: pointer;
    }
}

@media screen and (max-width: 450px) {
    .contact_phone {
        color: white;
        margin: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .contact_container_box {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        justify-content: center;
    }
    .locmailname {
        width: 100%;
        color: white;
        font-size: 15px;
        cursor: pointer;
    }
    .cotact_email {
        margin-left: -25px;
    }
}